exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-best-iac-alternative-tsx": () => import("./../../../src/pages/best-iac-alternative.tsx" /* webpackChunkName: "component---src-pages-best-iac-alternative-tsx" */),
  "component---src-pages-careers-senior-backend-developer-tsx": () => import("./../../../src/pages/careers/senior-backend-developer.tsx" /* webpackChunkName: "component---src-pages-careers-senior-backend-developer-tsx" */),
  "component---src-pages-careers-senior-cloud-engineer-tsx": () => import("./../../../src/pages/careers/senior-cloud-engineer.tsx" /* webpackChunkName: "component---src-pages-careers-senior-cloud-engineer-tsx" */),
  "component---src-pages-careers-senior-frontend-developer-tsx": () => import("./../../../src/pages/careers/senior-frontend-developer.tsx" /* webpackChunkName: "component---src-pages-careers-senior-frontend-developer-tsx" */),
  "component---src-pages-careers-technical-sales-specialist-tsx": () => import("./../../../src/pages/careers/technical-sales-specialist.tsx" /* webpackChunkName: "component---src-pages-careers-technical-sales-specialist-tsx" */),
  "component---src-pages-careers-ux-designer-tsx": () => import("./../../../src/pages/careers/ux-designer.tsx" /* webpackChunkName: "component---src-pages-careers-ux-designer-tsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-fractal-jsx": () => import("./../../../src/pages/fractal.jsx" /* webpackChunkName: "component---src-pages-fractal-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-jsx": () => import("./../../../src/pages/join-us.jsx" /* webpackChunkName: "component---src-pages-join-us-jsx" */),
  "component---src-pages-ocelot-jsx": () => import("./../../../src/pages/ocelot.jsx" /* webpackChunkName: "component---src-pages-ocelot-jsx" */),
  "component---src-pages-perche-scegliere-fractal-cloud-tsx": () => import("./../../../src/pages/perche-scegliere-fractal-cloud.tsx" /* webpackChunkName: "component---src-pages-perche-scegliere-fractal-cloud-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-temporary-fractal-highlights-tsx": () => import("./../../../src/pages/TemporaryFractalHighlights.tsx" /* webpackChunkName: "component---src-pages-temporary-fractal-highlights-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-why-choose-fractal-cloud-tsx": () => import("./../../../src/pages/why-choose-fractal-cloud.tsx" /* webpackChunkName: "component---src-pages-why-choose-fractal-cloud-tsx" */)
}

